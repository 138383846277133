import {
    CREATE_DISBURSEMENT_VOUCHER_REQUEST,
    CREATE_DISBURSEMENT_VOUCHER_SUCCESS,
    CREATE_DISBURSEMENT_VOUCHER_FAIL,
    DISBURSEMENT_VOUCHER_REQUEST,
    DISBURSEMENT_VOUCHER_SUCCESS,
    DISBURSEMENT_VOUCHER_FAIL,
    FIND_DISBURSEMENT_VOUCHER_REQUEST,
    FIND_DISBURSEMENT_VOUCHER_SUCCESS,
    FIND_DISBURSEMENT_VOUCHER_FAIL,
    UPDATE_DISBURSEMENT_VOUCHER_REQUEST,
    UPDATE_DISBURSEMENT_VOUCHER_SUCCESS,
    UPDATE_DISBURSEMENT_VOUCHER_FAIL,
    CLEAR_DISBURSEMENT_VOUCHER_ERROR,
    UNSUCCESSFUL_VOUCHERS_REQUEST,
    UNSUCCESSFUL_VOUCHERS_SUCCESS,
    UNSUCCESSFUL_VOUCHERS_FAIL
  } from "../../constants_Phase_2/RefundAndDisbursement/DisbursementVoucherConstant";
  
  // create
  export const disbursementVoucherReducer = (state = { disbursementVoucherList: [] }, action) => {
    switch (action.type) {
      case  DISBURSEMENT_VOUCHER_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          disbursementVoucherList: [],
          status: null,
          error: null,
        };

        case CREATE_DISBURSEMENT_VOUCHER_REQUEST:
          case UPDATE_DISBURSEMENT_VOUCHER_REQUEST:
          return {
            ...state,
            loading: true,
            message: null,
            status: null,
            error: null,
          };


      case DISBURSEMENT_VOUCHER_SUCCESS:
        return {
          ...state,
          loading: false,
          disbursementVoucherList: action.payload,
          totalData : action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };


      case  CREATE_DISBURSEMENT_VOUCHER_SUCCESS:
        case  UPDATE_DISBURSEMENT_VOUCHER_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          voucherId: action.voucherId,
          status: action.status,
        };
  
      case DISBURSEMENT_VOUCHER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_DISBURSEMENT_VOUCHER_FAIL:
        case UPDATE_DISBURSEMENT_VOUCHER_FAIL:
        return {
          ...state,
          loading: false,
          disbursementVoucherList: [...state.disbursementVoucherList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_DISBURSEMENT_VOUCHER_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  // find one
export const findDisbursementVoucherReducer = (state = { disbursementVoucher: {} }, action) => {
    switch (action.type) {
        case FIND_DISBURSEMENT_VOUCHER_REQUEST:
            return {
                disbursementVoucher: {},
                loading: true,
            };

        case FIND_DISBURSEMENT_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                disbursementVoucher: action.payload,
            };

        case FIND_DISBURSEMENT_VOUCHER_FAIL:
            return {
                ...state,
                loading: false,
                disbursementVoucher: {},
                error: action.payload
            };

        case "EMPTY_DISBURSEMENT_VOUCHER":
            return {
                ...state,
                disbursementVoucher: {}
            }

        case CLEAR_DISBURSEMENT_VOUCHER_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// create
export const unsuccessfulVouchersReducer = (state = { unsuccessfulVouchers: [] }, action) => {
  switch (action.type) {
    case UNSUCCESSFUL_VOUCHERS_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        unsuccessfulVouchers: [],
        status: null,
        error: null,
      };

    case UNSUCCESSFUL_VOUCHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        unsuccessfulVouchers: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case UNSUCCESSFUL_VOUCHERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_DISBURSEMENT_VOUCHER_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};